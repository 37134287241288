import React, { Suspense, lazy } from 'react';
import './App.scss';

const Carousel2 = lazy(() => import('./Carousel2'));

function App() {
    const address = 'Dc6yMgF1mfPw7v7e32Zb5YV14HRc9NUT1h27YiMjpump';
    const tg = 'https://t.me/gunterdestroyer';
    const dex = 'https://dexscreener.com/solana/Dc6yMgF1mfPw7v7e32Zb5YV14HRc9NUT1h27YiMjpump';
    const pump = 'https://pump.fun/Dc6yMgF1mfPw7v7e32Zb5YV14HRc9NUT1h27YiMjpump';
    function scrollToBottom() {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth' // This makes the scrolling smooth
        });
    }
    return (
        <div className="App">
            <div className="circle"></div>
            <div className="SecondPage">
                <img className="light" src="img.png" alt="Light" />
                <img className="solanacoin" src="/img_57.png" alt="Solana Coin" />
                <img src="/img_51.png" alt="cie" />
                <h1 className="Text1">COOLEST</h1>
                <h1 className="Text2">PENGUIN</h1>
                <h1 className="Text3">FROM THE</h1>
                <h1 className="Text4">LAND OF</h1>
                <h1 className="Text5">OOO</h1>
                <img className="P2" src="/img_9.png" alt="P2" />
                <img className="P99" src="/img_1.png" alt="P99" />
                <img className="P3" src="/img_47.png" alt="P3" />
                <div className="GUN"><h1>$GUNTER</h1></div>
            </div>
            <div className="ThirdPage">
                <h1>Tokenomics</h1>
                <img src="/img_13.png" className="side1" alt="Side 1" />
                <img src="/img_12.png" className="side2" alt="Side 2" />
                <img src="/img_11.png" className="clouds" alt="Clouds" />
                <div className="HH">
                    <div className="holder">
                        <p>Token Address:</p>
                        <div className="Item">
                            <p>{address}</p>
                        </div>
                    </div>
                    <div className="holder">
                        <p>Token Supply:</p>
                        <div className="Item2"><p>1,000,000,000</p></div>
                    </div>
                </div>
                <div className="holder2">
                    <h1>BURNED</h1>
                    <button><h1>100%</h1></button>
                </div>
            </div>
            <div className="FourthPage">
                <h1>About</h1>
                <div className="FPC">
                    <img className="lone" src="/img_17.png" alt="Lone" />
                    <div className="imgBox">
                        <img src="/img_15.png" alt="l1" />
                        <img className="imgAb" src="/img_16.png" alt="l2" />
                    </div>
                    <div className="FPCT">
                        <div><p><span className="l">$GUNTER</span> is a unique cryptocurrency inspired by Gunter, the lovable penguin from the iconic show, Adventure Time</p></div>
                        <a >
                            <button onClick={scrollToBottom}>I’m interested!</button>
                        </a>
                    </div>
                </div>
                <div className="FPC2">
                    <div className="FPCT2">
                        <div><p>Whether you’re a die-hard Adventure Time fan, a crypto enthusiast, or someone looking to be part of a unique and engaging community, <span>$GUNTER</span> is the place for you</p></div>
                        <a >
                            <button onClick={scrollToBottom}>Wow! I’m more interested</button>
                        </a>
                    </div>
                    <div className="imgBox">
                        <img src="/gunter-pingu-adventure-time 2.gif" alt="Gunter GIF" />
                    </div>
                </div>
                <div className="lastBlock">
                    <img src="/img_56.png" alt="l3" />
                    <img className="abs4" src="/img_53.png" alt="i53" />
                    <div>
                        <h1>HE IS:</h1>
                        <p>Cool AF ✅</p>
                        <p>Wearing glasses ✅</p>
                        <p>Wearing hat ✅</p>
                    </div>
                </div>
            </div>
            <div className="PageFive">
                <img src="/bob.png" className="abs99" alt="Bob" />
                <img className="abs2" src="/img_20.png" alt="I20" />
                <div className="abs2c">
                    <div>
                        <p>U still don’t have <span>$GUNTER</span> in your portfolio?</p>
                        <a >
                            <button onClick={scrollToBottom}>Change it now!</button>
                        </a>
                    </div>
                    <img src="/img_52.png" alt="I52" />
                </div>
            </div>
            <div className="PageSix">
                <div className="SPB">
                    <img className="p5" src="/img_24.png" alt="P5" />
                    <div className="SPB2">
                        <h1>REMEMBER</h1>
                        <p>Gunter - experienced penguin. He definitely knows about crypto</p>
                    </div>
                </div>
                <div className="CARA">
                    <Suspense fallback={<div>Loading...</div>}>
                        <Carousel2 />
                    </Suspense>
                </div>
                <img className="abs7" src="/without backgrnd.png" alt="Without Background" />
            </div>
            <div className="PageSeven">
                <div className="items-wrap">
                    <div className="items marquee">
                        {Array(10).fill().map((_, index) => (
                            <div className="item" key={index}><h1>wenk</h1></div>
                        ))}
                    </div>
                    <div aria-hidden="true" className="items marquee">
                        {Array(10).fill().map((_, index) => (
                            <div className="item" key={index}><h1>wenk</h1></div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="PageEight">
                <h1>How to buy?</h1>
                <div className="PEC">
                    <div className="PECC">
                        <div>
                            <p>1. Create a phantom wallet</p>
                            <p>2. Buy SOLANA</p>
                            <p>3. Swap SOLANA - $GUNTER</p>
                        </div>
                        <div className="PECCC">
                            <a href={tg}><img src="/zek3.svg" alt="Zek3" /></a>
                            <a href={dex}><img src="/zek2.svg" alt="Zek2" /></a>
                            <a href={pump}><img src="/zek1.svg" alt="Zek1" /></a>
                            <img className="abs3" src="/img_28.png" alt="I28" />
                        </div>
                    </div>
                    <img src="/57a5969aa12f3cefcc81c2fb859fa147 1.gif" alt="GIF" />
                </div>
            </div>
            <div className="Footer">
                <h1>$GUNTER</h1>
                <p>Please note that $GUNTER is a purely meme token with no intrinsic financial value. No guarantees are provided regarding the potential growth or decline of the token. $GUNTER is intended solely for entertainment purposes and has no affiliation with the Adventure Time franchise.</p>
            </div>
        </div>
    );
}

export default App;
